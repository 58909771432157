import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Pricing = () => (
    <StaticQuery query={graphql`
    {   
        wordpressPage(title: { eq: "Advertise" }){
            acf {
                heading
                sub_heading
            }
        }
        allWordpressWpPricing {
            edges {
           node {
             id
             title
             acf {
               duration
               duration_title
               starting_from
               price
               start_now_button
               start_now_link
             }
           }
         }
       }
    }
    `} render={props => (
        <div className="advertise-page">
            <div className="frame">
                <div className="advertise-title">{ props.wordpressPage.acf.heading }</div>
                {/* <div className="advertise-subtitle">{ props.wordpressPage.acf.sub_heading }</div> */}
                <div className="advertise-list">
                {
                    props.allWordpressWpPricing.edges.map(advertise => (
                    <div className="item" key={advertise.node.id}>
                        <div className="item-wrapper">
                            <div className="title">{advertise.node.title}</div>
                            <div className="duration">{advertise.node.acf.duration}</div>
                            <div className="duration-title">{advertise.node.acf.duration_title}</div>
                            <hr className="border"></hr>
                            <div className="starting-from">{advertise.node.acf.starting_from}</div>
                            <div className="price">{advertise.node.acf.price}</div>
                            {/* <a href={advertise.node.acf.start_now_link} className="start-now-button">{advertise.node.acf.start_now_button}</a> */}
                        </div>
                    </div>   
                    ))
                }
                </div>
            </div>
        </div>
    ) } />
);

export default Pricing;