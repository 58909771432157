import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const CreateCoupon = () => (
    <StaticQuery query={graphql`
    {   
        wordpressPage(title: { eq: "Advertise" }){
            acf {
                create_coupon_heading
              	yes_button_title
              	yes_button_link
              	no_button_title
              	bottom_heading
              	right_image {
              	    source_url
              	} 
            }
        }
    }
    `} render={props => (
        <div className="advertise-page">
            <div className="create-coupon">
                <div className="left">
                    <div className="left-wrapper">
                        <div className="coupon-heading" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.create_coupon_heading }}></div>
                        <div className="buttons">
                            <a className="yes" href={props.wordpressPage.acf.yes_button_link}>{props.wordpressPage.acf.yes_button_title}</a>
                            <a className="no" href="#">{props.wordpressPage.acf.no_button_title}</a>
                        </div>
                        {/* <div className="bottom-heading">{props.wordpressPage.acf.bottom_heading}</div> */}
                    </div>
                </div>
                <div className="right">
                    <img src={props.wordpressPage.acf.right_image.source_url} alt="" />
                </div>
            </div>
        </div>
    ) } />
);

export default CreateCoupon;