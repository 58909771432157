import React, {Component} from 'react'
import DefaultTemplate from "../templates/default"
import Pricing from "../components/advertise/pricing"
import CreateCoupon from "../components/advertise/create-coupon"
import AOS from 'aos';
import 'aos/dist/aos.css';

class Advertise extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        AOS.init({
            once: true
        })
    }

    render() {
        return <DefaultTemplate>
            <Pricing />
            <CreateCoupon />
        </DefaultTemplate>
    }
}
   
export default Advertise
   